/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import styled from "styled-components";

export const StyledNav = styled.div`
  z-index: 100;
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  color: white;
  padding: 0 225px 0 80px;
  @media (max-width: 1600px) {
    padding: 0 15vw 0 4vw;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1 {
    margin: 0;
    float: left;
    color: inherit;
    font-size: 20px;
  }

  .logo {
    color: white;
    min-width: 120px;
    width: 15vw;
    max-width: 200px;
    margin: 30px 0;
  }

  .menu {
    margin-left: auto;
    float: right;
    user-select: none;

    .navWrapper {
      position: fixed;
      top: 0px;
      right: 0px;
      height: 100vh;
      width: 60vw;
      background-color: #000;
      display: flex;
      align-items: center;

      ul {
        padding: 0;
        width: 100%;

        li {
          display: block;

          cursor: pointer;
          color: rgba(255, 255, 255, 0.8);
          border: 0px solid white;
          transition: all 300ms ease;
          font-size: 18px;
          &:hover {
            color: #e6b857;
          }

          a {
            font-weight: 400;
            margin: 5vh 0 5vh 20%;
            display: block;

            padding: 8px 40px;
            @media (max-width: 1600px) {
              padding: 8px 2vw;
            }
          }
        }
      }
    }
  }

  .nav {
    margin-left: auto;
    float: right;
    user-select: none;

    ul {
      padding: 0;
      display: flex;

      li {
        display: inline-block;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.8);
        border: 0px solid white;
        transition: all 300ms ease;
        font-size: 18px;
        &:hover {
          color: #e6b857;
        }

        a {
          display: inline-block;

          padding: 8px 40px;
          @media (max-width: 1600px) {
            padding: 8px 2vw;
          }

          margin: 0 15px;
          font-weight: 400;
        }
      }
    }
  }
`;
