import React from "react";
import styled from "styled-components";

export const HomeStyling = styled.div`
  display: flex;
  align-items: flex-start;
  height: auto;
  margin-bottom: 120px;

  .home-left {
    flex: 2;
    padding: 150px 0;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .dots {
      width: 60%;
      margin-top: 50px;
    }
  }

  .home-right {
    flex: 3;
    min-width: 600px;

    @media screen and (max-width: 767px) {
      .pic-wrapper {
        width: 100vw;
      }
    }

    .avatar {
      padding-left: 18%;
      width: 100%;
      height: auto;
      transition: transform 300ms ease-in-out;

      @media screen and (max-width: 767px) {
        object-fit: contain;
        width: 130%;
        padding-left: 0;
      }
    }

    .shapes-wrapper {
      position: absolute;
      z-index: 10;
      padding-top: 20%;
      width: 50%;
      height: auto;

      @media screen and (max-width: 992px) {
        padding-top: 220px;
      }

      @media screen and (max-width: 767px) {
        padding-top: 80%;
      }
    }

    .shapes {
      overflow: visible;
      position: absolute;
      width: 50%;
      max-width: 320px;

      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: 150px;
        padding-left: 10%;
      }
    }

    /*
    .square {
      overflow: visible;
      position: absolute;
      width: 26%;
      max-width: 150px;
    }
    .ellipse {
      overflow: visible;
      position: absolute;
      width: 30%;
      max-width: 180px;
      margin: 60px 0 0 90px;
    }
    */
    @media screen and (min-width: 1500px) {
      .avatar {
        margin-top: -120px;
      }
    }
  }
`;
