import React from "react";
import styled from "styled-components";

export const ModalStyling = styled.div`
  width: 80vw;
  max-width: 1400px;

  .modal-content {
    width: 100%;
    color: black;
    background-color: #fefefe;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #888;
    position: relative;

    .header {
      width: 100%;
      margin-bottom: 30px;
    }

    .image-wrapper {
      display: flex;
      align-items: stretch;

      .column {
        margin: 0 10px;
        flex: 1;

        img {
          width: 100%;
          margin-bottom: 20px;
          box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .modalClose {
      position: absolute;
      top: 20px;
      right: 30px;
      font-size: 35px;
      font-weight: bold;
      background: none;
      border: none;
      outline: none;
    }

    .modalClose:hover,
    .modalClose:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;
