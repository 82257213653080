import React from "react";
import styled from "styled-components";

export const MyButton = styled.button`
  background-color: ${(Props) => Props.theme.colors.primary};
  font-size: 16px;
  outline: none;
  color: white;
  border: none;
  width: fit-content;
  height: 43px;
  margin-right: 20px;
  padding: 0 25px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 500ms ease;
  margin-top: 40px;

  .buttonIcon {
    margin-right: 5px;
  }

  &:hover {
    transform: translateY(-3px);
    background-color: #08a175;
  }
`;
