import React from "react";
import styled from "styled-components";
import { prependOnceListener } from "cluster";

interface Props {
  right?: boolean;
}

export const WorkSectionStyling = styled.div<Props>`
  display: flex;
  margin-bottom: 200px;

  .work-image {
    position: relative;
    height: fit-content;
    max-width: 500px;
    width: auto;
    flex: 1;
    pointer-events: none;
    order: ${(p: Props) => (p.right ? "3" : "1")};
    /*margin-left: ${(p: Props) => (p.right ? "auto" : "0")};*/

    .sectionImage {
      width: 100%;
      height: 600px;
      max-height: auto;
      object-fit: cover;
      pointer-events: auto;
      transition: all 300ms ease;
      &:hover {
        filter: brightness(0.5);
        cursor: pointer;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }

    .overlay {
      display: table;
      transition: all 300ms ease;
      opacity: 0;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      text-align: center;

      h2 {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        -webkit-text-fill-color: rgba(0, 0, 0, 0);
        -webkit-text-stroke: 1px white;
      }
    }
  }

  .work-text {
    max-width: 550px;
    flex: 1;
    order: 2;
    margin: ${(p: Props) => (p.right ? "0 80px 0 auto" : "0 8vw 0 80px")};

    .playButton {
      margin-top: 40px;
    }

    h1 {
      padding-bottom: 0px;

      @supports (-webkit-text-stroke: 1px white) {
        span.stroke {
          -webkit-text-fill-color: rgba(0, 0, 0, 0);
          -webkit-text-stroke: 1px white;
          paint-order: stroke fill;
        }
      }
    }

    .tag {
      padding-bottom: 20px;
      text-transform: uppercase;
      color: #777;
      font-size: 0.8em;
    }

    p {
      color: #eee;

      a {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    .madeWith {
      .Psmall {
        padding: 40px 0 20px 0;
        font-size: 14px;
      }

      .icons-wrapper {
        display: flex;
        margin-bottom: 20px;
        height: 60px;

        img {
          display: block;
          max-width: 100px;
          max-height: 60px;
          height: auto;
          width: auto;
          object-fit: contain;

          margin-right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 10%;
    margin-bottom: 100px;

    .work-image {
      width: 100%;
      display: block;
    }

    .work-text {
      width: 100%;
      margin: 0;
      display: block;

      .MyButton,
      .playButton {
        margin-top: 20px;
      }

      .madeWith .icons-wrapper {
        flex-wrap: wrap;
        height: auto;
      }
    }
  }
`;
