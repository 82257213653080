import React from "react";
import { AboutStyling } from "./styles/about-style";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import avatar from "./assets/portret-avatar.png";

export const About = () => (
  // props.match.params.name
  <AboutStyling>
    <div className="left-wrapper">
      <img className="avatar" src={avatar} alt="avatar Tim" />
    </div>
    <div className="right-wrapper">
      <ScrollAnimation
        animateIn="fadeInUp"
        duration={1}
        offset={150}
        delay={100}
      >
        <h1>
          My
          <br />
          <span>Story.</span>
        </h1>
      </ScrollAnimation>
      <p>
        Hi! my name is Tim Pensart, i'm an interaction/UX design masters
        graduate from Hoeselt, Belgium. I have backgrounds in a wide range of
        digital skills, but i specialize in graphic design and development. So
        if you're looking for someone to design/develop a custom website, design
        a logo and complete corporate identity to get your brand out there, or
        you just have a creative project idea, feel free to contact me!
      </p>
    </div>
  </AboutStyling>
);
