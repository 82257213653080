import React from "react";
import styled from "styled-components";

export const FooterStyling = styled.div`
  padding-bottom: 60px;

  .footer-basic {
    display: flex;
    width: 30%;
    min-width: 400px;
    margin: 0 auto;
  }

  .footer-basic p {
    font-size: 16px;
    text-align: center;
    flex-grow: 1;
    color: #9e9e9e;
  }
`;
