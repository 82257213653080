import React from "react";
import styled from "styled-components";

export const AboutStyling = styled.div`
  padding: 100px 10%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .left-wrapper {
    margin-right: 5%;

    .avatar {
      flex-grow: 1;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }

  .right-wrapper {
    h1 {
      padding-top: 0;
    }

    h1 span {
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
      -webkit-text-stroke: 1px white;
      font-size: 1.2em;
    }

    p {
      max-width: 500px;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;

    .left-wrapper {
      width: 100%;
      margin: 0;

      .avatar {
        margin: 0 auto;
        display: block;
      }
    }

    .right-wrapper {
      width: 100%;
    }
  }
`;
