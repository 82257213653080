import React, { Fragment } from "react";
import { AboutStyling } from "./styles/about-style";
import { WorkStyling } from "./styles/work-style";
import { WorksSection } from "./components/works/works-section";
import SkateTerrain from "./assets/skateTerrainApp.png";
import Brasserellie from "./assets/Ellie_verjaardag_menu.png";
import BrasserellieAchter from "./assets/Ellie_verjaardag_menu_achter.png";
import ExploreTheGenerative from "./assets/lines.png";
import CompactMedia from "./assets/CompactMedia_presentatiemap_mockup.png";
import CompactMediaVisite from "./assets/CompactMedia_Visitekaartje_Folie_Blauw.png";
import CompactMediaNotitie from "./assets/CompactMedia_notitieblok_mockup.png";
import CompactMediaBanner from "./assets/Banner.png";
import CompactMediaBanner2 from "./assets/BannerTwo.png";
import MattenMan from "./assets/MattenMan.jpg";
import MattenManBrief from "./assets/MattenManBrief.jpg";
import FloatingMelodies from "./assets/floating-melodies-webpage.jpg";
import MestreechMerchImage from "./assets/MestreechMerch.png";
import MestreechMerchLogo from "./assets/MestreechMerchLogo.jpg";
import MestreechMerchLogoLines from "./assets/MestreechMerchLogoLines.jpg";
import MestreechMerchMockup from "./assets/MestreechMerchMockup.png";
import ArtiffactControl from "./assets/ArtiffactControliMac.png";
import FloroImage from "./assets/floro-mockup.png";
import fireBaseIcon from "./assets/icons/icons8-firebase-144.png";
import googleMapsIcon from "./assets/icons/icons8-google-maps-old-144.png";
import androidStudioIcon from "./assets/icons/android-studio-png-transparent.png";
import javaIcon from "./assets/icons/java-icon.png";
import htmlIcon from "./assets/icons/HTML5_logo.svg";
import cssIcon from "./assets/icons/CSS3_logo.svg";
import jsIcon from "./assets/icons/javascript.svg";
import figmaIcon from "./assets/icons/Figma-logo.png";
import plantIdIcon from "./assets/icons/plantid.png";
import photoshopIcon from "./assets/icons/photoshop.png";
import illustratorIcon from "./assets/icons/illustrator.png";
import InDesignIcon from "./assets/icons/InDesign.png";
import electronIcon from "./assets/icons/electron.png";
import reactIcon from "./assets/icons/react.png";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { MyButton } from "./components/button/button-style";
import GooglePlayButton from "./assets/icons/googleplay.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "./components/modal/modal";

const SkateTerrainText =
  "An app made for skaters with a passion for street skating. With SkateTerrain skaters will never have to forget skate spots again. You can check out any skate spot on the map or even add new ones if youd like!";

const SkateTerrainIcons = [
  { src: fireBaseIcon, name: "Firebase" },
  { src: googleMapsIcon, name: "google maps" },
  { src: androidStudioIcon, name: "android studio" },
  { src: javaIcon, name: "java" },
];

const FloroText =
  "Nowadays children have far less contact with nature than in the past. That's bad news, research results indicate that contact with nature is crucial for the physical and mental wellbeing of kids. That's why I made Floro for my masterproject, an app that can serve as an incentive for young people to explore nature. Floro is a search and learning game to discover the richness and diversity of plants in nature in a playful and educational way.";

const FloroIcons = [
  { src: figmaIcon, name: "Figma" },
  { src: androidStudioIcon, name: "android studio" },
  { src: javaIcon, name: "java" },
  { src: plantIdIcon, name: "Plant.id API" },
];

const FloatingMelodiesText =
  "Floating Melodies is an installation made for a school project. The height of the balloons determines the pitch of each note in the melody. The goal of this installation was to engage kids and provide an easy first step into making music.";

const FloatingMelodiesIcons = [
  { src: htmlIcon, name: "html" },
  { src: cssIcon, name: "css" },
  { src: jsIcon, name: "javascript" },
];

const BrasserellieText =
  "Menu and invitation card for the second birthday of Ellie. Printed in A5 format.";

const BrasserellieIcons = [
  {
    name: "illustrator",
    src: illustratorIcon,
  },
  {
    name: "inDesign",
    src: InDesignIcon,
  },
];

const ExploreTheGenerativeText = (
  <span>
    My bachelorproject called Explore The Generative is a website made to
    discover the world of generative art through music inputs. Generative art
    refers to art that is generated by a system with random variables so that
    each artwork is unique. For this project I explored and coded 6 different
    and unique systems. <br />
    <br />
    Feel free to try it out! It works with your keyboard or even a MIDI
    controller to make music and generate a completely unique artwork.
  </span>
);

const CompactMediaText = (
  <span>
    In this project I got to design the corporate identity for Compact Media.
    <br />
    <a href="https://www.compactmedia.be/" target="_blank">
      Compact media
    </a>{" "}
    is a media communication agency helping company's shape their story and grow
    their online presence. The assignment was to make a full blown brand
    identity with the existing logo of Compact Media. Because the logo is very
    recognisable I tried to bring it to the forefront and using it as a
    consistent style. I also seeked to reflect the bold feel of Compact Media in
    the brand identity.
  </span>
);

const CompactMediaIcons = [
  {
    name: "illustrator",
    src: illustratorIcon,
  },
  {
    name: "inDesign",
    src: InDesignIcon,
  },
  {
    name: "photoshop",
    src: photoshopIcon,
  },
];

const MattenManText =
  'This project is a corporate identity I made during my internship at Brainlane (Hasselt) for a fictional company called "MattenMan".';

const MattenManIcons = [
  {
    name: "illustrator",
    src: illustratorIcon,
  },
  {
    name: "photoshop",
    src: photoshopIcon,
  },
];

const MestreechMerchText =
  "In this project I made a logo for a local merch brand called Mestreech Merch in Maastricht, Netherlands. The goal of the project was to design a young and dynamic logo using the star and wings of the angel represented on the flag of Maastricht.";

const MestreechMerchIcons = [
  {
    name: "illustrator",
    src: illustratorIcon,
  },
  {
    name: "photoshop",
    src: photoshopIcon,
  },
];

const ArtiffactControlText = (
  <span>
    An{" "}
    <a href="https://osoc.be/editions/2020" target="_blank">
      Open Summer of Code 2020
    </a>{" "}
    project. ArTIFFact Control is a desktop application in which you can
    validate TIFF image files with information about cultural heritage
    collections."
  </span>
);

export const Work = () => (
  <WorkStyling>
    <WorksSection
      img={CompactMedia}
      p={CompactMediaText}
      icons={CompactMediaIcons}
      buttons={
        <Modal
          title="CompactMedia Brand identity"
          images={[
            CompactMedia,
            CompactMediaVisite,
            CompactMediaBanner,
            CompactMediaNotitie,
            CompactMediaBanner2,
          ]}
          trigger={<MyButton className="MyButton">Design</MyButton>}
        />
      }
      title={
        <span>
          Compact <span className="stroke">Media</span>
        </span>
      }
      tags={["Graphic design", "Branding"]}
    />
    <WorksSection
      img={MattenMan}
      p={MattenManText}
      icons={MattenManIcons}
      buttons={
        <Modal
          title="MattenMan Brand identity"
          images={[MattenMan, MattenManBrief]}
          trigger={<MyButton className="MyButton">Design</MyButton>}
        />
      }
      right
      title={
        <span>
          Matten<span className="stroke">Man</span>
        </span>
      }
      tags={["Graphic design", "Branding"]}
    />
    <WorksSection
      img={FloroImage}
      p={FloroText}
      icons={FloroIcons}
      buttons={[
        <a
          href="https://play.google.com/store/apps/details?id=com.timpensart.floro"
          target="_blank"
        >
          <img
            className="playButton"
            style={{
              marginRight: 20,
              display: "inline-block",
              verticalAlign: "top",
            }}
            height="43px"
            src={GooglePlayButton}
            alt=""
          />
        </a>,
        <a href="https://github.com/TimPensart/Floro" target="_blank">
          <MyButton className="MyButton">
            <FontAwesomeIcon className="buttonIcon" icon={faGithub} size="lg" />
            Github
          </MyButton>
        </a>,
        <a
          href="https://www.figma.com/proto/ciOHe0geX4AAlNHQsK3Snl/Floro-Master-Prototype"
          target="_blank"
        >
          <MyButton className="MyButton">Figma</MyButton>
        </a>,
      ]}
      title={
        <span>
          Floro <span className="stroke">App</span>
        </span>
      }
      tags={["App development", "UX/UI design", "Branding"]}
    />
    <WorksSection
      right
      img={SkateTerrain}
      p={SkateTerrainText}
      icons={SkateTerrainIcons}
      buttons={
        <a href="https://github.com/TimPensart/SkateTerrain" target="_blank">
          <MyButton className="MyButton">
            <FontAwesomeIcon className="buttonIcon" icon={faGithub} size="lg" />
            Github
          </MyButton>
        </a>
      }
      title={
        <span>
          Skate<span className="stroke">Terrain</span>
        </span>
      }
      tags={["App development", "UX/UI design", "Branding"]}
    />
    <WorksSection
      img={MestreechMerchImage}
      p={MestreechMerchText}
      icons={MestreechMerchIcons}
      buttons={
        <Modal
          title="Mestreech Merch"
          images={[
            MestreechMerchImage,
            MestreechMerchLogo,
            MestreechMerchLogoLines,
            MestreechMerchMockup,
          ]}
          trigger={<MyButton className="MyButton">Design</MyButton>}
        />
      }
      title={
        <span>
          Mestreech <span className="stroke">Merch</span>
        </span>
      }
      tags={["Branding", "logo design", "Merch"]}
    />
    <WorksSection
      img={FloatingMelodies}
      p={FloatingMelodiesText}
      icons={FloatingMelodiesIcons}
      buttons={
        <a href="https://www.timpensart.be/floating-melodies/" target="_blank">
          <MyButton className="MyButton">Website</MyButton>
        </a>
      }
      right
      title={
        <span>
          Floating <span className="stroke">Melodies</span>
        </span>
      }
      tags={["Front-end development", "UX/UI design"]}
    />
    <WorksSection
      img={Brasserellie}
      p={BrasserellieText}
      icons={BrasserellieIcons}
      buttons={
        <Modal
          title="Brasserellie"
          images={[Brasserellie, BrasserellieAchter]}
          trigger={<MyButton className="MyButton">Design</MyButton>}
        />
      }
      title={
        <span>
          Brasser<span className="stroke">ellie</span>
        </span>
      }
      tags={["Illustration", "Graphic design", "print design"]}
    />
    <WorksSection
      img={ExploreTheGenerative}
      p={ExploreTheGenerativeText}
      icons={FloatingMelodiesIcons}
      buttons={
        <a
          href="https://www.timpensart.be/ExploreTheGenerative/"
          target="_blank"
        >
          <MyButton className="MyButton">Try it out</MyButton>
        </a>
      }
      right
      title={
        <span>
          Explore <span className="stroke">the Generative</span>
        </span>
      }
      tags={["P5.js", "Front-end development"]}
    />
    <WorksSection
      img={ArtiffactControl}
      p={ArtiffactControlText}
      icons={[
        { src: figmaIcon, name: "figma" },
        { src: reactIcon, name: "react" },
        { src: electronIcon, name: "electron" },
      ]}
      buttons={[
        <a href="https://osoc20.github.io/ArTIFFact-Control/" target="_blank">
          <MyButton className="MyButton">
            <FontAwesomeIcon className="buttonIcon" icon={faGithub} size="lg" />
            Github
          </MyButton>
        </a>,
        <a
          href="https://www.figma.com/file/bJpNHJT4xGdcZAsjDO4car/OSOC-arTIFFact-control"
          target="_blank"
        >
          <MyButton className="MyButton">Figma</MyButton>
        </a>,
      ]}
      buttonText={"Show"}
      title={
        <span>
          ArTIFFact <span className="stroke">Control</span>
        </span>
      }
      tags={["UX/UI design", "Front-end development", "Electron", "Branding"]}
    />
    {/*
    <ExtraWorks />
    */}
  </WorkStyling>
);
