/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
import React, { useState, Component, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { WorkSectionStyling } from "./work-section-style";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { MyButton } from "../button/button-style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../modal/modal";

interface Props {
  right?: any;
  img: string;
  title?: JSX.Element | JSX.Element[];
  p?: string | JSX.Element | JSX.Element[];
  icons: { src: string; name: string }[];
  href?: string;
  modalContent?: JSX.Element | JSX.Element[];
  buttonText?: string;
  buttonIcon?: any;
  buttons: JSX.Element | JSX.Element[];
  tags: string[];
}

const off = 150;

export const WorksSection = (Props: Props) => {
  console.log(Props.icons);

  return (
    <WorkSectionStyling right={Props.right}>
      <div className="work-image">
        <ScrollAnimation
          animateIn={Props.right ? "fadeInRight" : "fadeInLeft"}
          duration={1}
          offset={off}
          delay={100}
        >
          <a href={Props.img} target="_blank">
            <img src={Props.img} className="sectionImage" />
          </a>
          <div className="overlay">
            <h2>Check me out</h2>
          </div>
        </ScrollAnimation>
      </div>

      <div className="work-text">
        <ScrollAnimation
          animateIn="fadeInUp"
          duration={1}
          offset={off}
          delay={200}
        >
          <h1>{Props.title}</h1>
          <p className="tag">
            {Props.tags.map((tag, index) => {
              if (index != Props.tags.length - 1) {
                return tag + " • ";
              } else {
                return tag;
              }
            })}
          </p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          duration={1}
          offset={off}
          delay={300}
        >
          <p>{Props.p}</p>
          <div className="madeWith">
            <p className="Psmall">Made with</p>
            <div className="icons-wrapper">
              {Props.icons.map((icon, i) => {
                return (
                  <img
                    key={i}
                    title={icon.name}
                    alt={icon.name}
                    src={icon.src}
                  />
                );
              })}
            </div>
          </div>
          {Props.buttons}
        </ScrollAnimation>
      </div>
    </WorkSectionStyling>
  );
};
