import React from "react";
import { FooterStyling } from "./styles/footer-style";

export const Footer = () => (
  <FooterStyling>
    <div className="footer-basic">
      <p className="mail">tim.pensart@hotmail.com</p>
      <p className="copyright">Tim Pensart © 2021</p>
    </div>
  </FooterStyling>
);
