import React from "react";
import Popup from "reactjs-popup";
import { ExtraWorksStyling } from "../works/extra-works-style";
import { ModalStyling } from "./modal-style";

interface ModalProps {
  trigger?: JSX.Element;
  title: string;
  images: string[];
}

export const Modal = (props: ModalProps) => {
  return (
    <Popup
      onOpen={() => {
        document.body.style.overflow = "hidden";
        console.log("opened");
      }}
      onClose={() => {
        document.body.style.overflow = "unset";
      }}
      overlayStyle={{
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100%",
        overflowY: "scroll",
        flexDirection: "column",
      }}
      contentStyle={{
        margin: "50px auto",
      }}
      trigger={props.trigger}
      modal
      nested
    >
      {(close: () => void) => (
        <ModalStyling>
          <div className="modal-content">
            <div className="header">
              <button className="modalClose" onClick={close}>
                &times;
              </button>
              <h1>{props.title}</h1>
            </div>
            <div className="image-wrapper">
              <div className="column left">
                {props.images.map((image, index) => {
                  if (index % 2 == 0) {
                    return (
                      <a href={image} target="_blank">
                        <img src={image} />
                      </a>
                    );
                  }
                })}
              </div>
              <div className="column right">
                {props.images.map((image, index) => {
                  if (index % 2 == 1) {
                    return (
                      <a href={image} target="_blank">
                        <img src={image} />
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </ModalStyling>
      )}
    </Popup>
  );
};
