/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { StyledNav } from "./navbar-style";
import logo from "../../assets/LogoName.svg";
import * as Scroll from "react-scroll";
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const ScrollLink = Scroll.Link;

export const Navbar = () => {
  const [isChecked, setChecked] = useState<boolean>(true);
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [scrollY, setScrollY] = useState<number>(window.scrollY);

  let lastKnownScrollPosition = 0;
  let ticking = false;

  const changeChecked = () => {
    setChecked(!isChecked);
  };

  window.addEventListener(
    "resize",
    function (event) {
      setWindowWidth(this.innerWidth);
      setWindowHeight(this.innerHeight);
    },
    true
  );

  window.addEventListener("scroll", function (e) {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function () {
        setScrollY(lastKnownScrollPosition);
        ticking = false;
      });

      ticking = true;
    }
  });

  window.addEventListener(
    "scroll",
    function (event) {
      setScrollY(this.scrollY);
    },
    true
  );

  return (
    <StyledNav>
      <div>
        <img className="logo" src={logo} alt="logo tim pensart" />
      </div>
      {windowWidth < 767 || scrollY > 80 ? (
        <div className="menu">
          <FontAwesomeIcon
            style={{
              position: "fixed",
              zIndex: 50,
              top: "30px",
              right: "30px",
              backgroundColor: "#1d1d1d",
              padding: "8px",
              border: "1px solid #555",
              boxSizing: "content-box",
              borderRadius: "80%",
              width: "22px",
              height: "22px",
            }}
            onClick={() => {
              if (!navOpen) {
                setHidden(false);
              }
              setNavOpen(!navOpen);
            }}
            icon={navOpen ? faTimes : faBars}
            size="lg"
          />
          <div
            className="navWrapper"
            onTransitionEnd={() => {
              if (navOpen == false) {
                setHidden(true);
              }
            }}
            style={{
              transition: "all 250ms ease-out",
              visibility: hidden ? "hidden" : "visible",
              opacity: navOpen ? "1" : "0",
              marginRight: navOpen ? "0" : -((windowWidth / 100) * 60) + "px",
            }}
          >
            <ul>
              <li>
                <ScrollLink
                  onClick={() => setNavOpen(false)}
                  to="work"
                  smooth={true}
                  duration={1000}
                >
                  Work
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  onClick={() => setNavOpen(false)}
                  to="about"
                  smooth={true}
                  duration={1000}
                >
                  About
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  onClick={() => setNavOpen(false)}
                  to="contact"
                  smooth={true}
                  duration={1000}
                >
                  Contact
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="nav">
          <ul>
            <li>
              <ScrollLink to="work" smooth={true} duration={1000}>
                Work
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="about" smooth={true} duration={1000}>
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="contact" smooth={true} duration={1000}>
                Contact
              </ScrollLink>
            </li>
          </ul>
        </div>
      )}
    </StyledNav>
  );
};
