/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";

export const MainStyle = styled.div`
  color: white;
  background-color: #1d1d1d;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 1600px;
  position: relative;
  margin: 0 auto;

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }
  h1,
  h2,
  h3 {
    padding: 40px 0 20px 0;
    color: white;
  }

  p {
    font-weight: 300;
    line-height: 1.4em;
    font-size: 18px;
  }
`;
